import axios from "axios";
import config from "../../config";


const apiUrl = import.meta.env.VITE_BASE_URL;
const header = config.headers;

export const obtenerCodigo = async (param) => {

    const configuracion = {
        headers : header,
        params : param
    }
    try {
        const response = await axios.get(`${apiUrl}code/send`, configuracion);
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const verificarCodigo = async (param) => {
    const configuracion = {
        headers : header,
        params : param
    }

    try {
        const response = await axios.get(`${apiUrl}code/verify`, configuracion);
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const getInfoUser = async (param, token) => {
    header['Authorization'] = `Bearer ${token}`;
    const configuracion = {
        headers : header,
        params : param
    }

    try {
        const response = await axios.get(`${apiUrl}client/info`, configuracion);
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}



export const getInfoPsychic = async (token) => {

    header['Authorization'] = `Bearer ${token}`;

    const configuracion = {
        headers : header
    }

    try {
        const response = await axios.get(`${apiUrl}show/psychics`, configuracion);
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const getInfoQuery = async (param,token) => {

    header['Authorization'] = `Bearer ${token}`;


    const configuracion = {
        headers : header,
        params : param
    }

    try {
        const response = await axios.get(`${apiUrl}calendar/packages/meeting`, configuracion);
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const getInfoDateCalendar = async (param, token) => {
    header['Authorization'] = `Bearer ${token}`;
    const configuracion = {
        headers : header,
        params : param
    }
    try {
        const response = await axios.get(`${apiUrl}calendar/day/meeting`, configuracion);
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const postCreateClient = async (param, token) => {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Authorization': `Bearer ${token}` // Ejemplo de encabezado de autorización
      };
    
     header['Authorization'] = `Bearer ${token}`;
     
    const response = await axios.post(`${apiUrl}client/create`, param, {
        headers
    });
    return response;
}

export const getSelectMeeting = async (param, token) => {
    header['Authorization'] = `Bearer ${token}`;
    const configuracion = {
        headers : header,
        params : param
    }
    try {
        const response = await axios.get(`${apiUrl}calendar/select/meeting`, configuracion);
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const postMeeting = async (param, token) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Authorization': `Bearer ${token}` 
      };
    
     header['Authorization'] = `Bearer ${token}`;


     try {
        const response = await axios.post(`${apiUrl}calendar/pay/meeting`, param, {
            headers
        });
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const postMeetingToken = async (param, token) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Authorization': `Bearer ${token}`
      };
    
     header['Authorization'] = `Bearer ${token}`;
   
    try {
        const response = await axios.post(`${apiUrl}calendar/pay/meeting/token`, param, {
            headers
        });
        return response;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const getSelecCustomer = async (param, token) => {
    header['Authorization'] = `Bearer ${token}`;
    const configuracion = {
        headers : header,
        params : param
    }
    try {

        return await axios.get(`${apiUrl}client/customer`, configuracion)
        .then((resp) =>  {
            return {status : resp.status, data : resp};
        })
        .catch((error) => {
            return {status : error.response.status, data : error};
        });
       
    } catch (error) {//
        return error
    }
}

export const postCreateCustomerStripe = async (param, token) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        //'Accept:':'*/*'
        'Authorization': `Bearer ${token}` // Ejemplo de encabezado de autorización
      };
     
    const response = await axios.post(`${apiUrl}client/create/customer/stripe`, param, {
        headers
    });
    return response;
}

export const patchUpdateClient = async (param) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        //'Accept:':'*/*'
        'Authorization': `Bearer ${sessionStorage.getItem('token')}` // Ejemplo de encabezado de autorización
      };
     
    const response = await axios.patch(`${apiUrl}client`, param, {
        headers
    });
    return response;
}

export const getViweHours = async (param) => {
    header['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    const configuracion = {
        headers : header,
        params : param
    }

    try {
        const resp = await axios.get(`${apiUrl}calendar/day/date/meeting`, configuracion);
        return resp;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const getMeetingToken = async(param) => {
    
    const headersparam = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      };

    try {
        const resp = await axios.get(`${apiUrl}meeting/${param}`, {headers: headersparam});
        return resp.data;
    } catch (error) {
        return {error: error, message: error.message}
    }

}

export const getMeetingTokenProvisional = async(param) => {
    const headersparam = {
        header : header,
    }

    try {
        const resp = await axios.get(`${apiUrl}meeting/auth/${param}`, {headersparam});
        return resp;
    } catch (error) {
        return {error: error, message: error.message}
    }

}

export const patchReagendarCliente = async (param, id) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        //'Accept:':'*/*'
        'Authorization': `Bearer ${sessionStorage.getItem('token')}` // Ejemplo de encabezado de autorización
      };
     
    const response = await axios.patch(`${apiUrl}calendar/meetings/${id}/reschedule`, param, {
        headers
    });
    return response;
}
